
.v-enter-active[data-v-c6674d], 
  .v-leave-active[data-v-c6674d] {

    transition: all 0.3s ease;
}
.v-enter-from[data-v-c6674d],
  .v-leave-to[data-v-c6674d] {

    opacity: 0;

    transform: translateX( -200px );
}

